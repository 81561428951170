import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const Login = React.lazy(() => import("../auth/Login"));

const NoAuthRoutes = () => {
  //Hook

  return (
    <div>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    </div>
  );
};

export default NoAuthRoutes;
