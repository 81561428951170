import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import ManageAccount from "../admin/account/ManageAccount";
// import ManagePrices from "../admin/prices/ManagePrices";

const CategoryList = React.lazy(() =>
  import("../agent/ManagePrices/components/CategoryList")
);
const Dashboard = React.lazy(() => import("../admin/dasboard/Dashboard"));
const Notification = React.lazy(() =>
  import("../admin/notification/Notification")
);

const ManageStatistics = React.lazy(() =>
  import("../admin/statistics/ManageStatistics")
);
const ManageUsers = React.lazy(() =>
  import("../utilities/components/ErrorPage")
);
const ManagePrices = React.lazy(() =>
  import("../agent/ManagePrices/ManagePrices")
);

const ErrorPage = React.lazy(() => import("../utilities/components/ErrorPage"));
const MainNavigation = React.lazy(() =>
  import("../admin/navigation/MainNavigation")
);

const ManageProducts = React.lazy(() =>
  import("../admin/products/ManageProducts")
);

const AgentRoutes = () => {
  //Hook

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/" element={<MainNavigation />}>
        <Route path="/dashboard" element={<Dashboard />} />
        {/* <Route path="/dashboard/prices" element={<ManagePrices />} />{" "} */}
        {/* <Route path="/dashboard/prices" element={<ManagePrices />} />{" "} */}
        <Route path="/dashboard/prices" element={<CategoryList />} />{" "}
        <Route
          path="/dashboard/prices/:categoryId"
          element={<ManagePrices />}
        />{" "}
        {/* <Route path="/dashboard/account" element={<ManageAccount />} />{" "} */}
        <Route path="/dashboard/statistics" element={<ManageStatistics />} />
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default AgentRoutes;
