import { Box, Center } from "@chakra-ui/react";
import React, { Suspense, useEffect } from "react";
import { PuffLoader } from "react-spinners";
import AdminRoutes from "./routes/AdminRoutes";
import AgentRoutes from "./routes/AgentRoutes";
import BloggerRoutes from "./routes/BloggerRoutes";
import InputTecnicianRoutes from "./routes/InputTecnicianRoutes";
import NoAuthRoutes from "./routes/NoAuthRoutes";
import ScrollToTop from "./utilities/functions/ScrollToTop";
import { usePosition } from "use-position";
import { useAuth } from "./utilities/hooks/auth-hook";

function App({ queryClient }) {
  const { type, token, user, checked, login, logout } = useAuth();

  const watch = false;

  const { latitude, longitude, speed, timestamp, accuracy, heading, error } =
    usePosition(watch);

  console.log({ latitude, longitude, error });
  let routes;
  if (token && type === "ADMIN") {
    routes = <AdminRoutes />;
    // token && type === "PARENT"
  } else if (token && type === "AGENT") {
    routes = <AgentRoutes />;
  } else if (type === "INPUT_CHECKER") {
    routes = <InputTecnicianRoutes />;
  } else if (type === "BLOGGER") {
    routes = <BloggerRoutes />;
  } else if (checked) {
    routes = <NoAuthRoutes />;
  }

  return (
    <div>
      <ScrollToTop />
      <Box bgColor={"#F7FAFC"}>
        <Suspense
          fallback={
            <Center alignSelf={"center"} minH="90vh">
              <PuffLoader size={65} color="#319284" />
            </Center>
          }
        >
          {checked ? (
            routes
          ) : (
            <Center my="100vh">
              <PuffLoader size={65} color="#319284" />
            </Center>
          )}
        </Suspense>
      </Box>
    </div>
  );
}

export default App;
