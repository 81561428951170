import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  //Hook

  // declare usefull auth states

  const [type, setType] = useState("NOAUTH");
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [checked, setChecked] = useState(false);

  //Function
  const login = useCallback((type, token, user) => {
    console.log({ type, token, user });
    setType(type);
    setToken(token);
    setUser(user);
    localStorage.setItem(
      "zareAdminAuthData",
      JSON.stringify({
        type,
        token,
        user,
      })
    );
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    setType("NOAUTH");
    setToken(null);
    localStorage.setItem(
      "zareAdminAuthData",
      JSON.stringify({
        type,
        token,
        user,
      })
    );
  }, []);

  let auth;

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("zareAdminAuthData"));
    console.log({ storedData });
    if (storedData) {
      if (storedData.token) {
        auth = login(storedData.type, storedData.token, storedData.user);
      }
    } else {
      auth = login("NOAUTH", null, null);
    }
    setChecked(true);
  }, [auth]);

  //Return
  return (
    <AuthContext.Provider value={{ type, user, token, checked, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
}
