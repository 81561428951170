import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ManageAccount from "../admin/account/ManageAccount";
const ManageMyBlogs = React.lazy(() =>
  import("../blogger/blogs/ManageMyBlogs")
);
const Dashboard = React.lazy(() => import("../admin/dasboard/Dashboard"));

const ErrorPage = React.lazy(() => import("../utilities/components/ErrorPage"));
const MainNavigation = React.lazy(() =>
  import("../admin/navigation/MainNavigation")
);

const ManageProducts = React.lazy(() =>
  import("../admin/products/ManageProducts")
);
const AddBlog = React.lazy(() => import("../blogger/blogs/components/AddBlog"));

const BloggerRoutes = () => {
  //Hook

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/" element={<MainNavigation />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/blogs" element={<ManageMyBlogs />} />

        <Route path="/dashboard/blogs/add" element={<AddBlog />} />

        {/* <Route path="/dashboard/account" element={<ManageAccount />} /> */}
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default BloggerRoutes;
