import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
// import ManageAccount from "../admin/account/ManageAccount";
const ManageBlogs = React.lazy(() =>
  import("../admin/blogs/blogs/ManageBlogs")
);
const ManagePrices = React.lazy(() =>
  import("../inputTecnician/prices/ManagePrices")
);

const Dashboard = React.lazy(() => import("../admin/dasboard/Dashboard"));

const ManageStatistics = React.lazy(() =>
  import("../admin/statistics/ManageStatistics")
);

const ErrorPage = React.lazy(() => import("../utilities/components/ErrorPage"));
const MainNavigation = React.lazy(() =>
  import("../admin/navigation/MainNavigation")
);

const ManageProducts = React.lazy(() =>
  import("../admin/products/ManageProducts")
);

const InputTecnicianRoutes = () => {
  //Hook

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/" element={<MainNavigation />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/prices" element={<ManagePrices />} />
        {/* <Route path="/dashboard/account" element={<ManageAccount />} />{" "} */}
        <Route path="/dashboard/statistics" element={<ManageStatistics />} />{" "}
      </Route>
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default InputTecnicianRoutes;
