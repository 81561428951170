import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
const MediaCategories = React.lazy(() =>
  import("../admin/medias/mediaCategories/MediaCategories")
);

const AddMedia = React.lazy(() =>
  import("../admin/medias/media/components/AddMedia")
);

const BlogCategories = React.lazy(() =>
  import("../admin/blogs/blogCategories/BlogCategories")
);

const Unit = React.lazy(() => import("../admin/units/Unit"));

const ProductCategory = React.lazy(() =>
  import("../admin/categories/ProductCategory")
);

const AddBlog = React.lazy(() => import("../blogger/blogs/components/AddBlog"));

const ManageUsers = React.lazy(() => import("../admin/users/ManageUsers"));
const ManageStatistics = React.lazy(() =>
  import("../admin/statistics/ManageStatistics")
);
const ManagePrices = React.lazy(() => import("../admin/prices/ManagePrices"));
const Notification = React.lazy(() =>
  import("../admin/notification/Notification")
);
const Dashboard = React.lazy(() => import("../admin/dasboard/Dashboard"));
const ManageBlogs = React.lazy(() =>
  import("../admin/blogs/blogs/ManageBlogs")
);

const ErrorPage = React.lazy(() => import("../utilities/components/ErrorPage"));

const MainNavigation = React.lazy(() =>
  import("../admin/navigation/MainNavigation")
);

const ManageProducts = React.lazy(() =>
  import("../admin/products/ManageProducts")
);
const AddProduct = React.lazy(() =>
  import("../admin/products/addProduct/AddProduct")
);
const ManageMedia = React.lazy(() =>
  import("../admin/medias/media/ManageMedia")
);

const AdminRoutes = () => {
  //Hook

  return (
    <Routes>
      <Route path="/dashboard" element={<MainNavigation />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/products" element={<ManageProducts />} />
        <Route
          path="/dashboard/categories"
          element={<ProductCategory />}
        />{" "}
        <Route path="/dashboard/units" element={<Unit />} />
        <Route
          path="/dashboard/products/add-product"
          element={<AddProduct />}
        />
        <Route path="/dashboard/users" element={<ManageUsers />} />
        <Route path="/dashboard/prices" element={<ManagePrices />} />
        <Route path="/dashboard/blogs" element={<ManageBlogs />} />
        <Route path="/dashboard/blog-categories" element={<BlogCategories />} />
        <Route path="/dashboard/media" element={<ManageMedia />} />
        <Route path="/dashboard/media-categories" element={<MediaCategories />} />
        <Route path="/dashboard/blogs/add" element={<AddBlog />} />
        <Route path="/dashboard/media/add" element={<AddMedia />} />
        {/* <Route path="/dashboard/account" element={<ManageAccount />} />{" "} */}
        <Route path="/dashboard/notification" element={<Notification />} />
        <Route
          path="/dashboard/statistics"
          element={<ManageStatistics />}
        />{" "}
        <Route path="*" element={<ErrorPage />} />
      </Route>{" "}
      <Route path="*" element={<Navigate to="/dashboard" />} />
    </Routes>
  );
};

export default AdminRoutes;
